import { REGION_INFO } from './regionBasedConstants';

export function regionBasedSchema(countryCode: string) {
  const country = REGION_INFO[countryCode];

  const countryBasedInfo = country
    ? {
        name: `PUMA ${country.name}`,
        legalName: `${country.legalName}`,
        logo: `${country.logo}`,
        image: `${country.image}`,
        url: `${country.url}`,
        telephone: `${country.telephone}`,
        address: {
          '@type': 'PostalAddress',
          streetAddress: `${country.streetAddress}`,
          addressLocality: `${country.addressLocality}`,
          postalCode: `${country.postalCode}`,
          addressCountry: `${country.addressCountry}`,
        },
        contactPoint: [
          {
            '@type': 'ContactPoint',
            telephone: `${country.telephone}`,
            email: `${country.email}`,
            areaServed: `${country.areaServed}`,
            contactType: 'customer service',
          },
        ],
      }
    : {};

  return {
    '@context': 'http://schema.org',
    '@type': 'Corporation',
    parentOrganization: {
      name: 'PUMA',
      legalName: 'PUMA SE',
      url: 'https://www.ngmijj.shop/',
      numberofEmployees: '13000',
      address: {
        '@type': 'PostalAddress',
        streetAddress: 'PUMA WAY 1',
        addressLocality: 'Herzogenaurach',
        postalCode: '91074',
        addressCountry: 'DE',
      },
      founder: {
        '@type': 'Person',
        name: 'Rudolf Dassler',
        sameAs: ['https://en.wikipedia.org/wiki/Rudolf_Dassler'],
      },
      foundingLocation: {
        '@type': 'Place',
        name: 'Herzogenaurach, Germany',
        sameAs: ['https://en.wikipedia.org/wiki/Herzogenaurach'],
      },
      ethicsPolicy:
        'https://about.puma.com/en/sustainability/codes-and-handbooks',
      foundingDate: '1948-01-10',
      brand: {
        '@type': 'Organization',
        name: 'PUMA',
      },
    },
    ...countryBasedInfo,
    brand: {
      '@type': 'Organization',
      Name: 'PUMA',
    },
    description:
      "PUMA is one of the world's leading sports brands, designing, developing, selling and marketing footwear, apparel and accessories. For over 65 years, PUMA has been producing the most innovative products for the fastest athletes on the planet. PUMA offers performance and sport-inspired lifestyle products in categories such as Football, Running and Training, Golf, and Motorsports. PUMA engages in exciting collaborations with renowned designers like STAPLE, STAMPD or TRAPSTAR to bring innovative and fast designs to the sports world. The PUMA Group owns the brands PUMA and COBRA Golf as well the subsidiary Dobotex. PUMA distributes its products in more than 120 countries and employs more than 13,000 people worldwide and is headquartered in Herzogenaurach/Germany.",
    sameAs: [
      'https://www.instagram.com/puma/',
      'https://www.instagram.com/pumasportstyle/',
      'https://x.com/puma',
      'https://www.linkedin.com/company/puma',
      'https://plus.google.com/+puma',
      'https://www.facebook.com/PUMA',
      'https://www.youtube.com/puma/',
      'https://en.wikipedia.org/wiki/Puma_(brand)',
      'https://www.crunchbase.com/organization/puma',
      'https://pumagroup.tumblr.com/',
      'https://about.puma.com/en',
      'https://www.reddit.com/r/PUMA/',
      'https://www.puma-catchup.com/',
      'https://www.youtube.com/user/puma',
      'https://www.wikidata.org/wiki/Q157064',
    ],
  };
}
